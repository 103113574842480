window.Logo = class Logo {
    website;
    element;
    title;
    src;

    constructor(element) {
        this.element = element;
        this.website = this.element.data('website');
        this.title = this.element.data('title');
        this.src = this.element.attr('src');
    }

    getModalContent() {
        var html = '<div class="btn btn-group"><a href="'+ this.src +'" target="_blank" class="btn btn-info">Open logo</a><a href="'+ this.website +'" target="_blank" class="btn btn-info">Bekijk website</a><button type="button" onclick="addToInput(\''+ this.src +'\')" class="btn btn-success">Selecteer logo</button></div>';

        return {
            title: this.title,
            imageUrl: this.src,
            html: html,
            showCancelButton: false,
            showConfirmButton: false
        };
    }
}