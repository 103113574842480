require('./bootstrap');

require('./app/toggle-password');

require('./app/select-all');

require('./app/select-multiple');

require('./app/swal');

require('./app/brands/Logo');

require('./app/blogs/Picture');

require('./app/set-system');

require('./app/advertisers/search');

require('./app/copy-to-clipboard');

require('./app/image-editor');


