window.Picture = class Picture {
    static imageModal(order, selected, system) {     
        Swal.fire(
            Picture.getModalContent(
                order, 
                selected,
                system
            )
        );
    }

    static getModalContent(order, selected, system) {
        var htmlBefore = '<ul class="list-group bg-light"><li class="list-group-item bg-light"><input type="file" accept="image/*" class="form-control bg-light" name="picture" onchange="Picture.listen('+ order +')"></li><li><ul class="list-group scrollable">';
        var htmlAfter = '';
        var images = Picture.getImages(system);

        images.forEach((image) => {
            var imgHtml = '<img src="https://panel.startsmall.nl/storage/images/'+ image.system.slug +'/'+ image.image +'" alt="'+ image.alt +'" loading="lazy" class="small">'+ image.alt +'';
            var buttonHtml = '<button type="button" class="btn btn-falcon-default" style="height: 2.5rem;" onclick="Picture.use('+ image.cms_image_id +', '+ order +')"><i class="fas fa-arrow-right"></i></button>';
            var buttonHtmlDisabled = '<button type="button" class="btn btn-falcon-default disabled" style="height: 2.5rem;" disabled><i class="fas fa-arrow-right"></i></button>';

            if(selected == image.cms_image_id) {
                htmlBefore += '<li class="list-group-item d-flex justify-content-between active">'+ imgHtml +' '+ buttonHtmlDisabled +'</li>';
            } else {
                htmlAfter += '<li class="list-group-item d-flex justify-content-between">'+ imgHtml +' '+ buttonHtml +'</li>';
            }
        });

        htmlAfter += "</ul><li></ul>";

        var html = htmlBefore + htmlAfter;

        return {
            html: html,
            showCancelButton: false,
            showConfirmButton: false
        };
    }

    static listen(order) {
        var files = document.querySelector('input[name=picture]').files;
        var id  = Picture.generateId(10);
        var newField = document.createElement('input');

        newField.setAttribute('type','file');
        newField.setAttribute('name','images_file['+ order +']');
        newField.setAttribute('id', id);
        newField.setAttribute('value', files[0].name);
        newField.setAttribute('class','d-none');
        document.querySelector('form[enctype="multipart/form-data"]').appendChild(newField);

        var newInput = document.getElementById(id);

        newInput.files = files;

        Picture.end(order);
    }

    static use(id, order) {
        var newField = document.createElement('input');

        newField.setAttribute('type','number');
        newField.setAttribute('name','images_id['+ order +']');
        newField.setAttribute('value', id);
        newField.setAttribute('class','d-none');
        document.querySelector('form[enctype="multipart/form-data"]').appendChild(newField);

        Picture.end(order);
    }

    static end(order) {
        var btn = $('#image_' + order);
        var btns = $('#image_' + order + '> button');

        btn.addClass('disabled').prop('disabled', true);
        btns.addClass("disabled").prop('disabled', true);
        Swal.close();
    }

    static delete(order) {
        var btns = $('#image_' + order + '> button');
        var newField = document.createElement('input');

        newField.setAttribute('type','number');
        newField.setAttribute('name','images_id['+ order +']');
        newField.setAttribute('value', 0);
        newField.setAttribute('class','d-none');
        document.querySelector('form[enctype="multipart/form-data"]').appendChild(newField);

        btns.addClass("disabled").prop('disabled', true);
    }

    static getImages(system) {
        var token = $('meta[name="csrf-token"]').attr('content');
        var result;

        $.ajax({
            type: "POST",
            url: "https://panel.startsmall.nl/images/get",
            async: false,
            data: {
                _token: token,
                system: system
            },
            success: function(response) {
                result = response;
            }
        });

        return result;
    }

    static generateId(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
}