window.setSystem = function () {
    var token = $('meta[name="csrf-token"]').attr('content');
    var system = $(event.target).val();

    $.ajax({
        type: "POST",
        url: "https://panel.startsmall.nl/system",
        data: {
            _token: token,
            system: system,
        }
    });
}