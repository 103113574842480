$(function() {
    $('#shops-search').keyup(function() {
        const input = this.value;
        const route = this.getAttribute('data-route');
        const system = this.getAttribute('data-system');
        const token = $('meta[name="csrf-token"]').attr('content');
        const resultList = $('#shops-search-result');

        $.ajax({
            type: "POST",
            url: route,
            async: false,
            data: {
                _token: token,
                input: input,
                system: system
            },
            success: function(shops) {
                resultList.html('');

                shops.forEach(shop => {
                    resultList.append('<li class="list-group-item" data-id="'+ shop.id +'"> '+ shop.name +' | '+ shop.system.name +' <button class="btn btn-info float-right" onclick="setShop('+ shop.id +', \''+ shop.system.name +'\', \''+ shop.name +'\')"><i class="fas fa-link"></i></button></li>');
                });
            }
        });
    });

    $('#affiliates-search').keyup(function() {
        const input = this.value;
        const route = this.getAttribute('data-route');
        const system = this.getAttribute('data-system');
        const token = $('meta[name="csrf-token"]').attr('content');
        const resultList = $('#affiliates-search-result');

        $.ajax({
            type: "POST",
            url: route,
            async: false,
            data: {
                _token: token,
                input: input,
                system: system
            },
            success: function(affiliates) {
                resultList.html('');

                affiliates.forEach(affiliate => {
                    resultList.append('<li class="list-group-item" data-id="'+ affiliate.id +'"> '+ affiliate.advertiser.name +' | '+ affiliate.system.name +' <button type="button" class="btn btn-info float-right" onclick="setAffiliate('+ affiliate.id +', \''+ affiliate.system.name +'\', \''+ affiliate.advertiser.name +'\')"><i class="fas fa-link"></i></button></li>');
                });
            }
        });
    });

    window.setAffiliate = function(id, system, advertiser) {
        const nameField = $('#advertiser-name');
        const formInput = $('#input-affiliate');
        const searchInput = $('#shops-search');

        searchInput.val(advertiser);
        formInput.val(id);
        nameField.html('<b>Advertiser</b><div class="alert alert-info">'+ advertiser + ' | ' + system +'</div>');

        checkInput();
    };

    window.setShop = function(id, system, shop) {
        const nameField = $('#shop-name');
        const formInput = $('#input-shop');

        formInput.val(id);
        nameField.html('<b>Shop</b><div class="alert alert-info">'+ shop + ' | ' + system +'</div>');

        checkInput();
    };

    window.checkInput = function() {
        const shopInput = $('#input-shop');
        const advertiserInput = $('#input-affiliate');
        const submitBtn = $('#submit-btn');

        if(shopInput.val() && advertiserInput.val()) {
            submitBtn.show();
        }
    };
});