
// Cropper Settings

var zoomable = false;
var zoomOnWheel = false;
var editBtns = false;
var background = false;
var modal = false;
var autoCrop = true;
var autoCropArea = 1;
var viewMode = 2;

// Variables

const editorContainer = document.getElementById('editor-container');
const previewContainer = document.getElementById("preview-container");

if (editorContainer && previewContainer) {
    let image = document.getElementById("image");
    let useButton = document.getElementById("use");
    let aspectRatioBtns = document.querySelectorAll(".aspect-ratio-btns");
    const fileInput = document.querySelector('input[type="file"]');
    const toggleButton = document.getElementById('toggle-editor');
    const rotateRightButton = document.getElementById("rotate-right");
    const rotateLeftButton = document.getElementById("rotate-left");
    const scaleXButton = document.getElementById("scale-X-button");
    const scaleYButton = document.getElementById("scale-Y-button");
    const previewButton = document.getElementById("preview");
    const previewImage = document.getElementById("preview-image");
    const options = document.querySelector(".options-btn");
    let url = editorContainer.getAttribute('data-img');
    let fileName = editorContainer.getAttribute('data-filename');
    let cropper = "";
    let scaleXClick = false,
        scaleYClick = false;
    let rotateRightValue = -45,
        rotateLeftValue = 45;

    // On load

    window.onload = () => {
        image.setAttribute("src", url);

        if (cropper) {
            cropper.destroy();
        } else {
            cropper = setCropper();
        }

        window.setTimeout(function () {
            editorContainer.style.display = "none";
        }, 300);
    };

    // Modal

    window.modal = function modal() {
        var logo = new Logo(
            $(event.target)
        );

        Swal.fire(logo.getModalContent());
    }

    // Event Listeners

    toggleButton.addEventListener("click", () => {
        if (editorContainer.style.display === "none") {
            editorContainer.style.display = "block";
        } else {
            editorContainer.style.display = "none";
        }
    });

    useButton.addEventListener("click", (e) => {
        useImage();
    });

    if (editBtns) {
        aspectRatioBtns.forEach((element) => {
            element.addEventListener("click", () => {
                if (element.innerText == "Free") {
                    cropper.setAspectRatio(NaN);
                } else {
                    cropper.setAspectRatio(eval(element.innerText.replace(":", "/")));
                }
            });
        });

        previewButton.addEventListener("click", () => {
            preview();
        });

        rotateRightButton.addEventListener("click", () => {
            cropper.rotate(rotateRightValue);
        });

        rotateLeftButton.addEventListener("click", () => {
            cropper.rotate(rotateLeftValue);
        });

        scaleXButton.addEventListener("click", () => {
            if (scaleXClick) {
                cropper.scaleX(1);
                scaleXClick = false;
            } else {
                cropper.scaleX(-1);
                scaleXClick = true;
            }
        });

        scaleYButton.addEventListener("click", () => {
            if (scaleYClick) {
                cropper.scaleY(1);
                scaleYClick = false;
            } else {
                cropper.scaleY(-1);
                scaleYClick = true;
            }
        });
    }

    // Functions

    window.addToInput = function addToInput(url) {
        $('#logo-label').html('Logo <span class="text-warning">(Extern logo geselecteerd)</span>');
        setImage(url);
    }

    function setCropper() {
        return new Cropper(image, {
            autoCrop: autoCrop,
            autoCropArea: autoCropArea,
            viewMode: viewMode,
            zoomable: zoomable,
            zoomOnWheel: zoomOnWheel,
            background: background,
            modal: modal,
        });
    }

    function preview() {
        previewContainer.classList.remove("d-none");

        let imgSrc = cropper.getCroppedCanvas({}).toDataURL();

        previewImage.src = imgSrc;
    }

    function useImage() {
        let imgSrc = cropper.getCroppedCanvas({}).toDataURL();
        var arr = imgSrc.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        var file = new File([u8arr], fileName, { type: mime });
        var dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInput.files = dataTransfer.files;
    }

    function setImage(url) {
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajax({
            type: "POST",
            url: "https://panel.startsmall.nl/google-api/request",
            data: {
                _token: token,
                url: url,
            }
        }).done(function (data, textStatus, jqXHR) {
            image.setAttribute("src", jqXHR.responseText);
            cropper.destroy();
            cropper = setCropper();

            Swal.close();
        });
    }

    // Interval

    setInterval(preview, 1000);
}